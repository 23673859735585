$('input[data-filter="1"]').click(function(event) {
    $('.filter-container .filtr-item[data-category="1"]').toggle();
});
$('input[data-filter="2"]').click(function(event) {
    $('.filter-container .filtr-item[data-category="2"]').toggle();
});
$('input[data-filter="3"]').click(function(event) {
    $('.filter-container .filtr-item[data-category="3"]').toggle();
});
$('input[data-filter="4"]').click(function(event) {
    $('.filter-container .filtr-item[data-category="4"]').toggle();
});