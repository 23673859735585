window.onload = function () {

  /* show / hide main nav
  -------------------------------------------------- */

  const nav = document.querySelector('.js-nav');
  const btnnav = document.querySelector('.js-toggle-icon');

  // Toggle menu to visible
  btnnav.addEventListener("click", () => {
    nav.classList.toggle('is-open');
  });

  // Toggle burger and close icon 
  btnnav.addEventListener("click", () => {
    // toggleCSSclasses(one, "icon-close");
    btnnav.classList.toggle('icon-close');
    btnnav.classList.toggle('icon-menu');
  });
};

