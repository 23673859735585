// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar'

// import Swiper JS
import Swiper, { Navigation, Pagination, scrollbar } from 'swiper/bundle';


var swiper = new Swiper('.js-swiper--standard', {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 10,
  loop: false,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  // Pagination fraction numbers
 
  scrollbar: {
    el: ".swiper-scrollbar",
    clickable: true,
  },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

});
var swiper = new Swiper(".mySwiper", {
  loop: false,
  spaceBetween: 10,
  slidesPerView: 3,
  freeMode: true,
  watchSlidesProgress: true,
});
var swiper2 = new Swiper(".mySwiper2", {
  loop: false,
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  scrollbar: {
    el: ".swiper-scrollbar",
    clickable: true,
  },
  thumbs: {
    swiper: swiper,
  },
});
export default Swiper;