var initCookieBar = function () {
  /**
   * Cookie-Bar
   * 
   * API dann über window.cookieHandler erreichbar
   */
  return (function () {
    var cookieBar = document.getElementById('js-cookie-layer'),
      cookieClose = document.getElementById('js-cookie-close'),
      cookieAcceptAll = document.getElementById('js-cookie-accept-all'),
      cookieTrackingCheckbox = document.getElementById('js-allowtracking'),
      cookieComfortCheckbox = document.getElementById('js-allowcomfort'),
      trackingLoaded = false,
      comfortLoaded = false;

    cookieClose.addEventListener("click", function () {
      var reload = false;
      setCookie("cookieAccept", "true", 30);

      if (cookieTrackingCheckbox && cookieTrackingCheckbox.checked == true) {
        setCookie("cookieTracking", "true", 30);
      } else {
        if (getCookie("cookieTracking") != "") {
          removeCookie('cookieTracking');
        }
        reload = true;
      }

      if (cookieComfortCheckbox && cookieComfortCheckbox.checked == true) {
        setCookie("cookieComfort", "true", 30);
      } else {
        if (getCookie('cookieComfort') != '') {
          reload = true;
        }
        removeCookie('cookieComfort');
      }
      if (reload) {
        location.reload();
        return;
      }

      checkCookie();
    });

    cookieAcceptAll.addEventListener("click", function () {
      // setze alle checkboxen auf checked
      if (cookieTrackingCheckbox)
        cookieTrackingCheckbox.checked = true;
      if (cookieComfortCheckbox)
        cookieComfortCheckbox.checked = true;
      cookieClose.click();
    });

    checkCookie();

    function setCookie(cName, cValue, cDaysTillExpire) {
      var d = new Date();
      d.setTime(d.getTime() + (cDaysTillExpire * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    }

    function removeCookie(sKey) { //, sDomain) {
      document.cookie = encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";// +
      // (sDomain ? "; domain=" + sDomain : "");
    }

    function getCookie(cookieName) {
      var name = cookieName + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      var cookieAccept = getCookie("cookieAccept");
      if (cookieAccept != '') {
        cookieBar.classList.add("is-hidden");
      }
      else {
        cookieBar.classList.remove("is-hidden");
      }

      var cookieTracking = getCookie('cookieTracking');
      if (cookieTracking != '') {
        if (cookieTrackingCheckbox)
          cookieTrackingCheckbox.checked = true;
        // console.log('lade tracking.js');
        loadTrackingScripts();
      } else {
        if (cookieTrackingCheckbox)
          cookieTrackingCheckbox.checked = false;
        // console.log('lösche tracking cookie');
      }

      var cookieComfort = getCookie('cookieComfort');
      if (cookieComfort != '') {
        if (cookieComfortCheckbox)
          cookieComfortCheckbox.checked = true;
        // console.log('lade comfort.js');
        loadComfortScripts();
      } else {
        if (cookieComfortCheckbox)
          cookieComfortCheckbox.checked = false;
        // console.log('lösche comfort cookie');
      }
    }

    /**
     * lädt alle Tracker
     */
    function loadTrackingScripts() {
      if (trackingLoaded) {
        return;
      }
      trackingLoaded = true;

      loadScript('assets/js/tracking.js');
    }

    /**
     * lädt alle Comfort-JS
     */
    function loadComfortScripts() {
      if (comfortLoaded) {
        return;
      }
      comfortLoaded = true;

      loadScript('assets/js/comfort.js');
    }


    function loadScript(url, callback) {
      var script = document.createElement('script');
      script.src = url;
      document.head.appendChild(script);

      if (callback) {
        script.onload = callback;
      }
    }

    // API 
    return {
      changeCookieSettings: function () {
        checkCookie();
        var element = document.getElementById("js-cookie-layer");
        element.classList.remove("is-hidden");
      }
    }

  })();
};

export default initCookieBar;